var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { raam } from 'raam-client-lib';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, } from 'react';
import { useImmer } from 'use-immer';
import DyteVideoBackgroundTransformer from '@dytesdk/video-background-transformer';
import { ParticipantsViews } from '../components';
import { AUDIO_INPUT_SETTINGS_KEY, AUDIO_OUTPUT_SETTINGS_KEY, BACKGROUND_BLUR_SETTINGS_KEY, CAMERA_ON_SETTINGS_KEY, DEFAULT_BACKGROUND_BLUR, DEFAULT_CAMERA_ON, DEFAULT_DEVICE, DEFAULT_MIC_ON, MIC_ON_SETTINGS_KEY, VIDEO_INPUT_SETTINGS_KEY, } from '../constants';
import { useIsMobile, useLocalStorageState, useRoomEvents } from '../hooks';
import { useMediaDevices } from '../hooks/useMediaDevices';
import { useRoomConfig } from '../hooks/useRoomConfig';
import { logger } from '../utils';
const isBackgroundBlurSupported = DyteVideoBackgroundTransformer.isSupported();
export const MeetingStoreContext = createContext(undefined);
export function MeetingStoreProvider({ children }) {
    const { meeting } = useDyteMeeting();
    const { roomConfig } = useRoomConfig(meeting.meta.meetingId, meeting.self.customParticipantId);
    const isMobile = useIsMobile();
    const { canSwitchCamera, switchCamera } = useMediaDevices();
    const [isCameraOn, setIsCameraOn] = useLocalStorageState(CAMERA_ON_SETTINGS_KEY, DEFAULT_CAMERA_ON);
    const [isBlurred, setIsBlurred] = useLocalStorageState(BACKGROUND_BLUR_SETTINGS_KEY, DEFAULT_BACKGROUND_BLUR);
    const [isMicOn, setIsMicOn] = useLocalStorageState(MIC_ON_SETTINGS_KEY, DEFAULT_MIC_ON);
    const [videoInput, setVideoInput] = useLocalStorageState(VIDEO_INPUT_SETTINGS_KEY, DEFAULT_DEVICE);
    const [audioInput, setAudioInput] = useLocalStorageState(AUDIO_INPUT_SETTINGS_KEY, DEFAULT_DEVICE);
    const [audioOutput, setAudioOutput] = useLocalStorageState(AUDIO_OUTPUT_SETTINGS_KEY, DEFAULT_DEVICE);
    const [showParticipantsList, setShowParticipants] = useImmer(false);
    const [isSettingsVisible, setIsSettingsVisible] = useImmer(false);
    const [participantsView, setParticipantsView] = useImmer(ParticipantsViews.GRID);
    const [isSpotlightMode, setSpotlightMode] = useImmer(false);
    const transformerRef = useRef(null);
    const toggleSpotlightMode = useCallback(() => {
        setSpotlightMode((activeSpotlightMode) => !activeSpotlightMode);
    }, [setSpotlightMode]);
    const toggleCamera = useCallback(() => {
        setIsCameraOn((b) => !b);
    }, [setIsCameraOn]);
    const toggleBlur = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsBlurred((b) => !b);
    }), [setIsBlurred]);
    const toggleMic = useCallback(() => {
        setIsMicOn((b) => !b);
    }, [setIsMicOn]);
    const setHideParticipantsList = useCallback(() => {
        setShowParticipants(false);
    }, [setShowParticipants]);
    const setShowParticipantsList = useCallback(() => {
        setShowParticipants(true);
    }, [setShowParticipants]);
    const toggleParticipantsList = useCallback(() => {
        setShowParticipants((show) => !show);
    }, [setShowParticipants]);
    const setShowSettings = useCallback(() => {
        setIsSettingsVisible(true);
    }, [setIsSettingsVisible]);
    const setHideSettings = useCallback(() => {
        setIsSettingsVisible(false);
    }, [setIsSettingsVisible]);
    const setGridParticipantsView = useCallback(() => {
        setParticipantsView(ParticipantsViews.GRID);
    }, [setParticipantsView]);
    const setSpeakerParticipantsView = useCallback(() => {
        setParticipantsView(ParticipantsViews.SPEAKER);
    }, [setParticipantsView]);
    const sendHandRaisingBroadcast = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!roomConfig.isHandRaised) {
            try {
                yield meeting.participants.broadcastMessage('hand-raise', {
                    name: meeting.self.name,
                    id: meeting.self.id,
                });
            }
            catch (error) {
                console.error('Error occurred while raising hand', error);
            }
        }
    }), [
        meeting.participants,
        meeting.self.id,
        meeting.self.name,
        roomConfig.isHandRaised,
    ]);
    const onCaptionToggle = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        // TODO further implement captioning
        try {
            yield raam.meetingRoom.setCaptioningState(meeting.meta.meetingId, meeting.self.customParticipantId, !roomConfig.isCaptioningEnabled);
        }
        catch (error) {
            console.error('Error occurred while toggling captioning', error);
        }
    }), [
        meeting.meta.meetingId,
        meeting.self.customParticipantId,
        roomConfig.isCaptioningEnabled,
    ]);
    const onHandRaiseToggle = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Promise.all([
                raam.meetingRoom.setHandRaise(meeting.meta.meetingId, meeting.self.customParticipantId, !roomConfig.isHandRaised),
                sendHandRaisingBroadcast(),
            ]);
        }
        catch (error) {
            console.error('Error occurred while raising hand', error);
        }
    }), [
        meeting.meta.meetingId,
        meeting.self.customParticipantId,
        roomConfig.isHandRaised,
        sendHandRaisingBroadcast,
    ]);
    const handleRemoveParticipant = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            yield raam.meetingRoom.removeRoomParticipant(meeting.meta.meetingId, meeting.self.customParticipantId);
        }
        catch (error) {
            logger(error, 'Error removing participant from room config', {
                level: 'error',
            });
        }
    }), [meeting.meta.meetingId, meeting.self.customParticipantId]);
    useEffect(() => {
        if (!isBackgroundBlurSupported) {
            return;
        }
        const initializeVideoBackgroundTransformer = () => __awaiter(this, void 0, void 0, function* () {
            // Uncomment the following line to disable per frame canvas rendering for v2 sdk
            meeting.self.setVideoMiddlewareGlobalConfig({
                disablePerFrameCanvasRendering: true,
            });
            if (isBackgroundBlurSupported && !transformerRef.current) {
                try {
                    const videoBackgroundTransformer = yield DyteVideoBackgroundTransformer.init({ meeting }); // For v2 SDK
                    transformerRef.current =
                        yield videoBackgroundTransformer.createBackgroundBlurVideoMiddleware(10);
                }
                catch (e) {
                    logger(e, 'Error while initializing DyteVideoBackgroundTransformer', {
                        level: 'trace',
                    });
                    transformerRef.current = null;
                }
            }
            else {
                logger('DyteVideoBackgroundTransformer is not supported');
            }
        });
        initializeVideoBackgroundTransformer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array ensures this runs only once
    useEffect(() => {
        if (!meeting.self.roomJoined ||
            !isBackgroundBlurSupported ||
            !(transformerRef === null || transformerRef === void 0 ? void 0 : transformerRef.current)) {
            return;
        }
        const addBlurEffect = () => {
            if (transformerRef.current) {
                meeting.self.addVideoMiddleware(transformerRef.current);
            }
        };
        // Helper function to remove the video middleware
        const removeBlurEffect = () => {
            if (transformerRef.current) {
                meeting.self.removeVideoMiddleware(transformerRef.current);
            }
        };
        if (isBlurred && meeting.self.roomJoined && isBackgroundBlurSupported) {
            addBlurEffect();
        }
        else {
            removeBlurEffect();
        }
        return () => {
            removeBlurEffect();
        };
    }, [isBlurred, transformerRef, meeting.self, meeting.self.roomJoined]);
    useRoomEvents(undefined, handleRemoveParticipant);
    const contextValue = useMemo(() => ({
        isCameraOn,
        isBlurred,
        isMicOn,
        isMobile,
        isSettingsVisible,
        onCaptionToggle,
        onHandRaiseToggle,
        participantsView,
        setGridParticipantsView,
        setHideParticipantsList,
        setHideSettings,
        setIsSettingsVisible,
        setParticipantsView,
        setShowParticipantsList,
        setShowSettings,
        setSpeakerParticipantsView,
        showParticipantsList,
        switchCamera,
        toggleCamera,
        toggleBlur,
        toggleMic,
        toggleParticipantsList,
        canSwitchCamera,
        isSpotlightMode,
        toggleSpotlightMode,
        isBackgroundBlurSupported,
        videoInput,
        audioInput,
        audioOutput,
        setVideoInput,
        setAudioInput,
        setAudioOutput,
    }), [
        isCameraOn,
        isBlurred,
        isMicOn,
        isMobile,
        isSettingsVisible,
        onCaptionToggle,
        onHandRaiseToggle,
        participantsView,
        setGridParticipantsView,
        setHideParticipantsList,
        setHideSettings,
        setIsSettingsVisible,
        setParticipantsView,
        setShowParticipantsList,
        setShowSettings,
        setSpeakerParticipantsView,
        showParticipantsList,
        switchCamera,
        toggleCamera,
        toggleBlur,
        toggleMic,
        toggleParticipantsList,
        canSwitchCamera,
        isSpotlightMode,
        toggleSpotlightMode,
        videoInput,
        audioInput,
        audioOutput,
        setVideoInput,
        setAudioInput,
        setAudioOutput,
    ]);
    return (_jsx(MeetingStoreContext.Provider, Object.assign({ value: contextValue }, { children: children })));
}
export function useMeetingStore() {
    const context = useContext(MeetingStoreContext);
    if (!context) {
        throw new Error('useMeetingStore must be used within a MeetingStoreProvider');
    }
    return context;
}
